import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "context/context";
import Spinner from "react-bootstrap/Spinner";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";

import { useGetOneDeviceApiMutation } from "state/slices/webSlices"; // Used for API call (Rtk-query)

function Devices() {
  const [devices, setDevices] = useState([]);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [userRole, setUserRole] = useState(0);

  const [getOneDeviceApi] = useGetOneDeviceApiMutation(); //For API call

  // Fetch Devices via API
  useEffect(() => {
    async function fetchDevices() {
      setUserRole(user.role);
      setLoading(true);
      try {
        const response = await getOneDeviceApi({ email: user.email });
        setDevices(response.data.responseDataa);
      } catch (error) {
        console.error("Error fetching devices:", error);
        // Handle error (e.g., set an error state)
      } finally {
        setLoading(false);
      }
    }

    fetchDevices();
  }, [user.email]); // Make sure to include dependencies if user.email changes

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Devices</CardTitle>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Index</th>
                        <th>Device Name</th>
                        <th>Device ID</th>
                        {/* <th>Map</th> */}
                        <th>Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* List of Devices */}
                      {devices.map((device, index) => {
                        let deviceArr = device.split(":");
                        let deviceName = deviceArr[0].trim();
                        let deviceId = deviceArr[1].trim();
                        // let map = `map/${device}`;
                        let link = `/${
                          userRole == 1 ? "admin" : "user"
                        }/graph/${device}`; // Correct URL for Graph
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{deviceName}</td>
                            <td>{deviceId}</td>
                            <td>
                              <Link to={link}>Link</Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Devices;
