import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider as ContextProvider } from "./context/context"; // Renamed to avoid confusion with react-redux Provider
import { Provider } from "react-redux";
import { store } from "state/store/store";

import Admin from "layouts/Admin/Admin.js";
import User from "layouts/User/User";
import Login from "./views/Login";
import ForgetPassword from "views/ForgetPassword";
import ScrollToTop from "./ScrollToTop";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

function scrollTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ContextProvider>
      <ThemeContextWrapper>
        <BackgroundColorWrapper>
          <BrowserRouter>
            <div
              className="back-to-top d-flex align-items-center justify-content-center"
              onClick={scrollTop}
            >
              <span style={{ fontSize: "25px", color: "white" }}>&#8593;</span>
            </div>
            <ScrollToTop />
            <Routes>
              <Route path="/admin/*" element={<Admin />} />
              <Route path="/user/*" element={<User />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/" element={<Navigate to="/login" />} />
            </Routes>
          </BrowserRouter>
        </BackgroundColorWrapper>
      </ThemeContextWrapper>
    </ContextProvider>
  </Provider>
);
