import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice } from "@reduxjs/toolkit";

export const sasloWebApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "",
  }),
  tagTypes: ["Device", "User"],

  endpoints: (builder) => ({
    getOneDeviceLocationApi: builder.mutation({
      query: (body) => ({
        url: "https://uxo9tmpbqk.execute-api.eu-central-1.amazonaws.com/fetchOneDeviceLocation",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Device"],
    }),

    getOneDeviceApi: builder.mutation({
      query: (body) => ({
        url: "https://5rtjwvf30c.execute-api.eu-central-1.amazonaws.com/fetchOneDevice",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Device"],
    }),

    getIotDataApi: builder.mutation({
      query: (body) => ({
        url: "https://qst03zmx07.execute-api.eu-central-1.amazonaws.com/fetchIotData/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Device"],
    }),

    setNewPasswordApi: builder.mutation({
      query: (body) => ({
        url: "https://816i4n9kg5.execute-api.eu-central-1.amazonaws.com/setNewPassword/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),

    forgotUserPassword: builder.mutation({
      query: (body) => ({
        url: "https://o08ldhzes0.execute-api.eu-central-1.amazonaws.com/forgotUserPassword/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),

    loginApi: builder.mutation({
      query: (body) => ({
        url: "https://k1ypbu8dp0.execute-api.eu-central-1.amazonaws.com/login/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),

    signUpApi: builder.mutation({
      query: (body) => ({
        url: "https://a727gw24h9.execute-api.eu-central-1.amazonaws.com/signUp/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["User"],
    }),

    getDeviceStatus: builder.mutation({
      query: (body) => ({
        url: "https://p3rznmzw9i.execute-api.eu-central-1.amazonaws.com/fetchDeviceStatus",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Device"],
    }),

    getMonthlyDataApi: builder.mutation({
      query: (body) => ({
        url: "https://3cktddor11.execute-api.eu-central-1.amazonaws.com/prod/web-fetchMonthlyData",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Device"],
    }),

    updateDeviceApi: builder.mutation({
      query: (body) => ({
        url: "https://cvme23aphj.execute-api.eu-central-1.amazonaws.com/updateDevice/",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Device"],
    }),
  }),
});

export const {
  useGetOneDeviceLocationApiMutation,
  useGetOneDeviceApiMutation,
  useGetIotDataApiMutation,
  useSetNewPasswordApiMutation,
  useForgotUserPasswordMutation,
  useLoginApiMutation,
  useSignUpApiMutation,
  useGetDeviceStatusMutation,
  useGetMonthlyDataApiMutation,
  useUpdateDeviceApiMutation,
} = sasloWebApi;

const initialState = {
  currentDeviceID: "",
};

export const deviceSlice = createSlice({
  name: "currentDevice",
  initialState,
  reducers: {
    setCurrentDeviceID: (state, action) => {
      state.currentDeviceID = action.payload;
    },
  },
});

export const { setCurrentDeviceID } = deviceSlice.actions;
