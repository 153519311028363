import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { SENSORS } from "../constants";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Alert,
} from "reactstrap";

import { useSignUpApiMutation } from "state/slices/webSlices"; //Use for Api calling (Rtk-query)

function RegisterUser() {
  // State
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: false,
    signUpMsg: false,
    error: null,
  });

  const [signUpApi] = useSignUpApiMutation(); // for api call

  const [devices, setDevices] = useState([]);
  const [sensorsData, setSensorsData] = useState([]);
  const [currSensorsData, setCurrSensorsData] = useState([]);

  // add item in the devices
  function addItem() {
    let deviceName = document.getElementById("deviceName");
    let deviceId = document.getElementById("deviceId");

    if (deviceName.value !== "" && deviceId.value !== "") {
      const newItem = deviceName.value.trim() + ":" + deviceId.value.trim();
      const list = [...devices];
      list.push(newItem);

      setSensorsData([
        ...sensorsData,
        { device: newItem, arr: currSensorsData },
      ]);
      setDevices(list);
    } else {
      setLoadingAndMsg({
        loading: false,
        signUpMsg: false,
        error: "Please Enter Device Name and ID!",
      });
    }

    deviceName.value = "";
    deviceId.value = "";
    setCurrSensorsData([]);
  }

  function handleSensor(sensor) {
    if (!currSensorsData.includes(sensor)) {
      setCurrSensorsData([...currSensorsData, sensor]);
    }
  }
  function handleRemoveSensor(sensor) {
    const temp = currSensorsData.filter((e) => e !== sensor);
    setCurrSensorsData(temp);
  }

  function deleteItem(i) {
    let deviceDel = document.getElementById("device-" + i).innerText;
    let list = [...devices];
    let sensorList = [...sensorsData];

    list = list.filter((d) => d != deviceDel);
    sensorList = sensorList.filter((e) => e.device !== deviceDel);
    setSensorsData(sensorList);
    setDevices(list);
  }

  // form validation and calling registerUser API
  const validateSignup = (e) => {
    e.preventDefault();

    // Getting all from inputs
    let email = e.target.signupEmail.value;
    let fName = e.target.signupFName.value;
    let lName = e.target.signupLName.value;
    let password = e.target.signupPassword.value;
    let cPassword = e.target.signupPasswordAgain.value;
    // let devices = e.target.devices.value;
    let errDiv = document.getElementById("errDiv");

    //Error Messages
    let errMsg = [];

    //Regex
    let regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    // Validating all inputs
    if (email === "" || !regex.test(email)) {
      errMsg.push("Please enter the valid Email address");
    }

    if (fName === "") {
      errMsg.push("Please enter the First Name");
    }

    if (lName === "") {
      errMsg.push("Please enter the Last Name");
    }

    if (password === "") {
      errMsg.push("Please enter the Password");
    }

    if (!passwordRegex.test(password)) {
      errMsg.push("Password should be of minimum 8 characters");
      errMsg.push(
        "Password must contain a Number, an Uppercase, Lowercase and Special character"
      );
    }

    if (cPassword === "") {
      errMsg.push("Please enter the Confirm Password");
    }

    if (cPassword !== password) {
      errMsg.push("Password and Confirm Password Should Match!");
    }

    // If there is error in user input then displaying those errors
    if (errMsg.length > 0) {
      errDiv.style.display = "block";
      errDiv.style.color = "red";
      errDiv.style.border = "1px solid red";
      errDiv.style.borderRadius = "10px";
      errDiv.style.marginBottom = "1rem";
      errDiv.style.padding = "0.5rem";

      let msgs = "";

      for (let i = 0; i < errMsg.length; i++) {
        msgs += errMsg[i] + "<br/>";
      }

      errDiv.innerHTML = msgs;
      window.scrollTo(0, 0);
    } else {
      // if no error message -> send register req to register API
      setLoadingAndMsg({
        loading: true,
        signUpMsg: false,
        error: null,
      });
      errDiv.style.display = "none";

      // role is sent by deault to  normal user
      const data = {
        email: email,
        firstName: fName,
        lastName: lName,
        password: password,
        devices: devices,
        sensors: sensorsData,
      };

      // sending register request
      signUpApi(data)
        .then((res) => {
          // if there is error e.g. email already exists then display error
          if (res.data.errorType) {
            setLoadingAndMsg({
              loading: false,
              signUpMsg: false,
              error: res.data.errorMessage,
            });
          } else {
            // if registration successfull -> display message to verify email
            // Promting user a message to verify email
            setLoadingAndMsg({
              loading: false,
              signUpMsg: true,
              error: null,
            });
          }
        })
        .catch((err) =>
          setLoadingAndMsg({
            loading: false,
            signUpMsg: false,
            error: "Error Occured",
          })
        );
      window.scrollTo(0, 0);
    }
  };

  function addAnotherUser() {
    setLoadingAndMsg({
      loading: false,
      signUpMsg: false,
      error: null,
    });
    setDevices([]);
  }

  const { loading, signUpMsg, error } = loadingAndMsg;

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            {/* Show loader while signing up */}
            {loading ? (
              <div className="text-center" style={{ height: "70vh" }}>
                <Spinner
                  className="justify-content-center"
                  animation="grow"
                  style={{
                    height: "10rem",
                    width: "10rem",
                    marginTop: "13rem",
                    color: "#263B90",
                  }}
                />
                <h6 style={{ padding: "1rem" }}>Loading...</h6>
              </div>
            ) : (
              <Card style={{ borderRadius: "1rem" }}>
                <CardHeader>
                  <h3 className="">Register New User</h3>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={(e) => validateSignup(e)}>
                    <Row>
                      <Col className="pl-md-1" md="8">
                        <FormGroup>
                          <div
                            name="errDiv"
                            id="errDiv"
                            style={{ display: "none" }}
                          ></div>
                        </FormGroup>
                      </Col>
                    </Row>

                    {error ? (
                      <Row>
                        <Col className="pl-md-1" md="8">
                          <FormGroup>
                            <div
                              name="errDiv"
                              id="errDiv"
                              style={{
                                display: "block",
                                fontSize: "1rem",
                                fontWeight: "500",
                                border: "1px solid red",
                                borderRadius: "10px",
                                padding: "0.5rem",
                                color: "red",
                              }}
                            >
                              {error}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : (
                      <div></div>
                    )}

                    {signUpMsg ? (
                      <Row>
                        <Col className="pl-md-1" md="6">
                          <FormGroup>
                            <Alert
                              color="success"
                              style={{ fontSize: "1.1rem" }}
                            >
                              <p>User Registered Successfully !</p>
                              <p>
                                Verification Link is send to registered user
                              </p>
                            </Alert>
                          </FormGroup>
                          <Row>
                            <Col className="pl-md-1 mt-4" md="6">
                              <FormGroup>
                                <Button
                                  id="addMoreUser"
                                  className="btn-fill"
                                  color="primary"
                                  onClick={() => addAnotherUser()}
                                >
                                  Register Another User
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <Row>
                          <Col className="pl-md-1" md="8">
                            <FormGroup>
                              <label htmlFor="exampleInputEmail1">
                                First Name
                              </label>
                              <Input
                                placeholder="Enter Firstname"
                                type="text"
                                name="signupFName"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-md-1" md="8">
                            <FormGroup>
                              <label htmlFor="exampleInputEmail1">
                                Last Name
                              </label>
                              <Input
                                placeholder="Enter Lastname"
                                type="text"
                                name="signupLName"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-md-1" md="8">
                            <FormGroup>
                              <label htmlFor="exampleInputEmail1">
                                Email address
                              </label>
                              <Input
                                placeholder="Enter Email"
                                type="text"
                                name="signupEmail"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-md-1" md="8">
                            <FormGroup>
                              <label htmlFor="password">Password</label>
                              <Input
                                type="password"
                                placeholder="Enter Password"
                                name="signupPassword"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="pl-md-1" md="8">
                            <FormGroup>
                              <label htmlFor="password">Confirm Password</label>
                              <Input
                                type="password"
                                placeholder="Enter Password Again"
                                name="signupPasswordAgain"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="3">
                            <label htmlFor="deviceName">Device Name</label>
                            <Input
                              type="text"
                              id="deviceName"
                              placeholder="Enter Device Name"
                              name="deviceName"
                            />
                          </Col>

                          <Col xs="3">
                            <label htmlFor="deviceId">Device ID</label>
                            <Input
                              type="text"
                              id="deviceId"
                              placeholder="Enter Device ID"
                              name="deviceId"
                            />
                          </Col>

                          <Col xs="2" style={{ marginTop: "-10px" }}>
                            <label>Select Sensor</label>
                            <DropdownButton
                              id="select_sensor"
                              variant="warning"
                              title="Sensors"
                            >
                              {SENSORS.map((e, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleSensor(e)}
                                >
                                  {e}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="8">
                            <ListGroup
                              type="unstyled"
                              style={{ background: "inherit" }}
                            >
                              {currSensorsData.map((item, i) => {
                                return (
                                  <ListGroupItem
                                    key={item}
                                    style={{
                                      background: "inherit",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      border: "none",
                                      padding: "0.5rem",
                                    }}
                                  >
                                    <span id={"currSensor-" + i}>{item}</span>
                                    <Button
                                      className="btn-fill"
                                      color="danger"
                                      onClick={() => handleRemoveSensor(item)}
                                    >
                                      Delete Sensor
                                    </Button>
                                  </ListGroupItem>
                                );
                              })}
                            </ListGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="2">
                            <Button
                              className="btn-fill"
                              color="success"
                              onClick={addItem}
                            >
                              ADD
                            </Button>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="8">
                            <ListGroup
                              type="unstyled"
                              style={{ background: "inherit" }}
                            >
                              {devices.map((item, i) => {
                                return (
                                  <ListGroupItem
                                    key={item}
                                    style={{
                                      background: "inherit",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                      border: "1px solid #2b3553",
                                      padding: "0.5rem 0.8rem",
                                      marginBottom: "12px",
                                    }}
                                  >
                                    <span id={"device-" + i}>{item}</span>
                                    {sensorsData
                                      .find((e) => e.device === item)
                                      ?.arr?.map((s) => (
                                        <span>{s}</span>
                                      ))}
                                    <Button
                                      className="btn-fill"
                                      color="danger"
                                      onClick={() => deleteItem(i)}
                                    >
                                      Delete
                                    </Button>
                                  </ListGroupItem>
                                );
                              })}
                            </ListGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="pl-md-1 mt-4" md="8">
                            <FormGroup>
                              <Button
                                id="loginBtn"
                                className="btn-fill"
                                color="primary"
                                type="submit"
                              >
                                Register
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default RegisterUser;
