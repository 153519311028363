import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
// Google Map related imports
import {
  APIProvider,
  Map as GMap,
  AdvancedMarker,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { UserContext } from "context/context";

import {
  useGetOneDeviceLocationApiMutation,
  useGetDeviceStatusMutation,
} from "state/slices/webSlices"; // Use for API calling (Rtk-query)
import { setCurrentDeviceID } from "state/slices/webSlices";

function Map() {
  const [getOneDeviceLocationApi] = useGetOneDeviceLocationApiMutation();
  const [getDeviceStatus] = useGetDeviceStatusMutation();

  const dispatch = useDispatch(); // For set currentDeviceId to redux

  const [loc, setLoc] = useState(null);
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: true,
    msg: null,
  });
  const [loadingInPin, setLoadingInPin] = useState(false);

  const { user } = useContext(UserContext);

  // Get Info of Devices like location, sensoors's data etc..
  const getDevicesInfo = async () => {
    let tempActiveDevices = await getDeviceStatus({ email: user.email });
    let devices = tempActiveDevices?.data?.body;
    let device_Arr = [];

    for (let i = 0; i < devices?.length; i++) {
      let device_data = devices[i]?.data;

      setLoadingAndMsg({
        // setting loadingAndMsg: to remove loading spinner and display error if any
        loading: true,
        msg: !device_data ? "Loading..." : null,
      });

      if (device_data) {
        let position = {
          lat: device_data.payload?.latitude
            ? parseFloat(device_data.payload.latitude)
            : parseFloat(device_data.payload.GPS[1]),
          lng: device_data.payload?.longtitude
            ? parseFloat(device_data.payload.longtitude)
            : parseFloat(device_data.payload.GPS[0]),
        };
        let data = {
          enclTempRH: `${device_data?.payload.sensor_SHT_1[0]}C/${device_data?.payload.sensor_SHT_1[1]}%`,
          envTempRH: `${device_data?.payload.sensor_SHT_2[0]}C/${device_data?.payload.sensor_SHT_2[1]}%`,
          bhTempRH: `${device_data?.payload.sensor_SHT_3[0]}C/${device_data?.payload.sensor_SHT_3[1]}%`,
          weight1: `${(device_data?.payload.sensor_HX711_1 / 1000).toFixed(
            2
          )}Kg`,
          weight2: `${(device_data?.payload.sensor_HX711_2 / 1000).toFixed(
            2
          )}Kg`,
          battery: `${device_data?.payload?.battery?.percentage} %`,
        };

        device_Arr.push({
          id: devices[i]?.device_id,
          dateTime: new Date(
            device_data?.payload.datetime * 1000
          ).toUTCString(),
          position: position,
          data: data,
        });
      }

      setLoc(device_Arr);
      setLoadingAndMsg({
        // setting loadingAndMsg: to remove loading spinner and display error if any
        loading: false,
        msg: !device_data ? "Loading..." : null,
      });
    }
  };

  // For getting Updated data
  const onClickPinGetFreshData = async (id) => {
    let device_Arr = loc;
    setLoadingInPin(true);
    const device_id = id;

    let tempDevices = await getOneDeviceLocationApi({
      device_id: device_id.split(":")[1],
    });

    tempDevices = tempDevices?.data?.responseDataa?.Items;

    if (tempDevices?.length !== 0) {
      let position = {
        lat: tempDevices[0]?.payload?.latitude
          ? parseFloat(tempDevices[0]?.payload.latitude)
          : parseFloat(tempDevices[0]?.payload.GPS[1]),
        lng: tempDevices[0]?.payload?.longtitude
          ? parseFloat(tempDevices[0]?.payload.longtitude)
          : parseFloat(tempDevices[0]?.payload.GPS[0]),
      };

      let data = {
        enclTempRH: `${tempDevices[0]?.payload.sensor_SHT_1[0]}C/${tempDevices[0]?.payload.sensor_SHT_1[1]}%`,
        envTempRH: `${tempDevices[0]?.payload.sensor_SHT_2[0]}C/${tempDevices[0]?.payload.sensor_SHT_2[1]}%`,
        bhTempRH: `${tempDevices[0]?.payload.sensor_SHT_3[0]}C/${tempDevices[0]?.payload.sensor_SHT_3[1]}%`,
        weight1: `${(tempDevices[0]?.payload.sensor_HX711_1 / 1000).toFixed(
          2
        )}Kg`,
        weight2: `${(tempDevices[0]?.payload.sensor_HX711_2 / 1000).toFixed(
          2
        )}Kg`,
        battery: `${tempDevices[0]?.payload?.battery?.percentage} %`,
      };

      setLoadingInPin(false);
      device_Arr = device_Arr.filter((item) => item.id !== id);

      device_Arr.push({
        id: device_id,
        dateTime: new Date(
          tempDevices[0].payload.datetime * 1000
        ).toUTCString(),
        position: position,
        data: data,
      });

      setLoc(device_Arr);
    }
  };

  const navigate = useNavigate(); // Replacing useHistory with useNavigate

  const mapOnClick = (deviceId, locationDetails) => {
    loc.map((l) => {
      if (
        l.position.lat === locationDetails.lat &&
        l.position.lng === locationDetails.lng
      ) {
        dispatch(setCurrentDeviceID(deviceId));
        if (user.role == "1") {
          navigate(`/admin/graph/${deviceId}`);
        } else {
          navigate(`/user/graph/${deviceId}`);
        }
      }
    });
  };

  const analyticsOnClick = (deviceId, locationDetails) => {
    loc.map((l) => {
      if (
        l.position.lat === locationDetails.lat &&
        l.position.lng === locationDetails.lng
      ) {
        if (user.role == "1") {
          navigate(`/admin/analytics/${deviceId}`);
        } else {
          navigate(`/user/analytics/${deviceId}`);
        }
      }
    });
  };

  // Get Info of Devices like location, sensoors's data etc..
  useEffect(() => {
    async function fetchDevicesInfo() {
      await getDevicesInfo();
    }

    fetchDevicesInfo();
  }, []);

  const { loading } = loadingAndMsg;
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    map.setMapTypeId("satellite");

    const bounds = new window.google.maps.LatLngBounds();
    loc.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  // Options to be applied on the map
  const mapOptions = {
    mapId: "53f238f5a840d360",
    draggable: true, // enable dragging
    zoomControl: true, // enable zoom control
    maxZoom: 20, // adjust max zoom
    minZoom: 5, // adjust min zoom
    mapTypeId: "satellite",
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>Google Maps</CardHeader>
              <CardBody>
                <div
                  id="map"
                  className="map"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <APIProvider
                    apiKey={"AIzaSyBx5T_PYJcmo5tmh6bnaEyPGRp4AmYUN3w"}
                  >
                    {!loading ? (
                      <GMap
                        defaultZoom={12}
                        defaultCenter={{ lat: 40.236015, lng: 22.496614 }}
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                        clickableIcons={true}
                        // gestureHandling='auto'
                        // options={mapOptions}
                        onLoad={handleOnLoad}
                        onClick={() => setActiveMarker(null)}
                        // draggableCursor='pointer'
                        key={"gmap"}
                        {...mapOptions}
                      >
                        {loc.map(({ id, position, data, dateTime }) => (
                          <React.Fragment key={id}>
                            <AdvancedMarker
                              key={id}
                              position={position}
                              onClick={() => handleActiveMarker(id)}
                            >
                              {/* <Pin background={'#4285F4'} borderColor={'#0F9D58'} /> */}
                              {activeMarker === id ? (
                                <InfoWindow
                                  position={position}
                                  onCloseClick={() => setActiveMarker(null)}
                                >
                                  {loadingInPin ? (
                                    <div className="text-center">
                                      <Spinner
                                        className="justify-content-center"
                                        animation="grow"
                                        style={{
                                          height: "7rem",
                                          width: "7rem",
                                          marginTop: "3.5rem",
                                          color: "#263B90",
                                        }}
                                      />
                                      <h6 style={{ padding: "1rem" }}>
                                        Loading...
                                      </h6>
                                    </div>
                                  ) : (
                                    <div>
                                      <div>
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: 15,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {id}
                                        </span>
                                      </div>

                                      <div>
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: 15,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Time:
                                        </span>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "black",
                                          }}
                                        >
                                          {dateTime}
                                        </span>
                                      </div>

                                      <hr />

                                      <div>
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: 15,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Encl. Temp/RH:
                                        </span>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "black",
                                          }}
                                        >
                                          {data.enclTempRH}
                                        </span>
                                      </div>

                                      <div>
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: 15,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Env. Temp/RH:
                                        </span>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "black",
                                          }}
                                        >
                                          {data.envTempRH}
                                        </span>
                                      </div>

                                      <div>
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: 15,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          BH. Temp/RH:
                                        </span>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "black",
                                          }}
                                        >
                                          {data.bhTempRH}
                                        </span>
                                      </div>

                                      <div>
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: 15,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Weight 1:
                                        </span>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "black",
                                          }}
                                        >
                                          {data.weight1}
                                        </span>
                                      </div>

                                      <div>
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: 15,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Weight 2:
                                        </span>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "black",
                                          }}
                                        >
                                          {data.weight2}
                                        </span>
                                      </div>

                                      <div>
                                        <span
                                          style={{
                                            color: "black",
                                            fontSize: 15,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Battery:
                                        </span>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "black",
                                          }}
                                        >
                                          {data.battery}
                                        </span>
                                      </div>

                                      <button
                                        onClick={() => mapOnClick(id, position)}
                                        style={{
                                          backgroundColor: "#2184F8",
                                          color: "white",
                                          fontSize: "15px",
                                          padding: "5px 5px",
                                          borderRadius: "5px",
                                          border: "0px",
                                          margin: "5px 5px",
                                        }}
                                      >
                                        See Graph
                                      </button>

                                      <button
                                        onClick={() =>
                                          analyticsOnClick(id, position)
                                        }
                                        style={{
                                          backgroundColor: "#2184F8",
                                          color: "white",
                                          fontSize: "15px",
                                          padding: "5px 5px",
                                          borderRadius: "5px",
                                          border: "0px",
                                          margin: "5px 5px",
                                        }}
                                      >
                                        See Analytics
                                      </button>

                                      <button
                                        onClick={() =>
                                          onClickPinGetFreshData(id)
                                        }
                                        style={{
                                          backgroundColor: "#2184F8",
                                          color: "white",
                                          fontSize: "15px",
                                          padding: "5px 5px",
                                          borderRadius: "5px",
                                          border: "0px",
                                          margin: "5px 5px",
                                        }}
                                      >
                                        Refresh
                                      </button>
                                    </div>
                                  )}
                                </InfoWindow>
                              ) : null}
                            </AdvancedMarker>
                          </React.Fragment>
                        ))}
                      </GMap>
                    ) : (
                      <div key={"loading"} className="text-center">
                        <Spinner
                          className="justify-content-center"
                          animation="grow"
                          style={{
                            height: "7rem",
                            width: "7rem",
                            marginTop: "3.5rem",
                            color: "#263B90",
                          }}
                        />
                        <h6 style={{ padding: "1rem" }}>Loading...</h6>
                      </div>
                    )}
                  </APIProvider>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Map;
