import React, { useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { Nav } from "reactstrap";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

import { useSelector } from "react-redux"; // For get values from redux which was stored

let ps;

function Sidebar({ routes = [{}], rtlActive = false, logo, toggleSidebar }) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);

  const currentDeviceID = useSelector(
    (state) => state.currentDevice.currentDeviceID
  ); // Get currentDeviceID from redux state

  const activeRoute = (routeName) =>
    location.pathname === routeName ? "active" : "";

  React.useEffect(() => {
    // Initialize PerfectScrollbar if the user is on a Windows platform
    if (/Win/.test(navigator.userAgent)) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    return () => {
      if (ps) ps.destroy();
    };
  }, []);

  let logoImg = null;
  let logoText = null;

  if (logo) {
    if (logo.outterLink) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          rel="noreferrer"
          onClick={toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          rel="noreferrer"
          onClick={toggleSidebar}
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={toggleSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </Link>
      );
      logoText = (
        <Link
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={toggleSidebar}
        >
          {logo.text}
        </Link>
      );
    }
  }

  return (
    <BackgroundColorContext.Consumer>
      {({ color }) => (
        <div className="sidebar" data={color}>
          <div className="sidebar-wrapper" ref={sidebarRef}>
            {logoImg || logoText ? (
              <div className="logo">
                {logoImg}
                {logoText}
              </div>
            ) : null}
            <Nav>
              {routes.map((prop, key) => {
                if (prop.redirect) return null;
                return (
                  <li
                    style={{
                      // When currentDeviceID is Not selected, Graphs and Statistics are not show in sidebar
                      display: currentDeviceID
                        ? "inherit"
                        : ["Graphs", "Statistics"].includes(prop.name)
                        ? "none"
                        : "inherit",
                    }}
                    className={
                      activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link active"
                      onClick={toggleSidebar}
                    >
                      <i className={prop.icon} />
                      <p>{rtlActive ? prop.rtlName : prop.name}</p>
                    </NavLink>
                  </li>
                );
              })}
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    text: PropTypes.node,
    imgSrc: PropTypes.string,
  }),
  toggleSidebar: PropTypes.func,
};

export default Sidebar;
