// import React, { useContext, useEffect, useState } from "react";
import Map from "./Map";

function Dashboard() {
  return (
    <>
      <Map />
    </>
  );
}

export default Dashboard;
