import Dashboard from "views/Dashboard.js";
import Graphs from "views/Graphs";
import TableList from "views/TableList.js";
import Devices from "views/Devices.js";
import RegisterUser from "views/RegisterUser";
import UpdateDevices from "views/UpdateDevices";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-pin",
    element: <Dashboard />, // Updated to use `element`
    layout: "/admin",
  },
  {
    path: "/devices",
    name: "Devices",
    icon: "tim-icons icon-mobile",
    element: <Devices />, // Updated to use `element`
    layout: "/admin",
  },
  {
    path: "/graph/:id",
    name: "Graphs",
    icon: "tim-icons icon-sound-wave",
    element: <Graphs />, // Updated to use `element`
    layout: "/admin",
  },
  {
    path: "/analytics/:id",
    name: "Statistics",
    icon: "tim-icons icon-chart-bar-32",
    element: <TableList />, // Updated to use `element`
    layout: "/admin",
  },
  {
    path: "/register",
    name: "Register New User",
    icon: "tim-icons icon-single-02",
    element: <RegisterUser />, // Updated to use `element`
    layout: "/admin",
  },
  {
    path: "/updateDevices",
    name: "Update Devices",
    icon: "tim-icons icon-pencil",
    element: <UpdateDevices />, // Updated to use `element`
    layout: "/admin",
  },

  {
    path: "/devices",
    name: "Devices",
    icon: "tim-icons icon-mobile",
    element: <Devices />, // Updated to use `element`
    layout: "/user",
  },
  {
    path: "/graph/:id",
    name: "Graphs",
    icon: "tim-icons icon-sound-wave",
    element: <Graphs />, // Updated to use `element`
    layout: "/user",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "tim-icons icon-pin",
    element: <Dashboard />, // Updated to use `element`
    layout: "/user",
  },
  {
    path: "/analytics/:id",
    name: "Statistics",
    icon: "tim-icons icon-chart-bar-32",
    element: <TableList />, // Updated to use `element`
    layout: "/user",
  },
];

export default routes;
