import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Alert,
} from "reactstrap";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { Bar, Line } from "react-chartjs-2";
import ReactSelect from "react-select";
import {
  useGetMonthlyDataApiMutation,
  useGetIotDataApiMutation,
} from "state/slices/webSlices"; // Use For Api calling (Rtk-query
import { useDispatch, useSelector } from "react-redux";
import { setCurrentDeviceID } from "state/slices/webSlices";

function Tables() {
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: true,
    msg: null,
  });
  const [graphLoadingAndMsg, setGraphLoadingAndMsg] = useState({
    graphLoading: true,
    msg: null,
  });

  const dispatch = useDispatch(); // For set currentDeviceId to redux

  const currentDeviceID = useSelector(
    (state) => state.currentDevice.currentDeviceID
  ); // Get currentDeviceID from redux state

  useEffect(() => {
    if (id !== ":id") {
      dispatch(setCurrentDeviceID(id));
    }
  }, [id]);

  useEffect(() => {
    if (id === ":id") {
      id = currentDeviceID;
    }
  }, [currentDeviceID]);

  const [getMonthlyDataApi] = useGetMonthlyDataApiMutation();
  const [getIotDataApi] = useGetIotDataApiMutation();

  // Table 1 states
  const [temp1Value, setTemp1Value] = useState([]);
  const [temp2Value, setTemp2Value] = useState([]);
  const [temp3Value, setTemp3Value] = useState([]);
  const [weight1Value, setWeight1Value] = useState([]);
  const [weight2Value, setWeight2Value] = useState([]);
  const [humidity1Value, setHumidity1Value] = useState([]);
  const [humidity2Value, setHumidity2Value] = useState([]);
  const [humidity3Value, setHumidity3Value] = useState([]);

  // Table 2 states
  const [currentweight1, setCurrentweight1] = useState(0);
  const [currentweight2, setCurrentweight2] = useState(0);
  const [currWeight1at9, setCurrWeight1at9] = useState(0);
  const [currWeight2at9, setCurrWeight2at9] = useState(0);

  // Table 3 states
  const [weight1at9, setWeight1at9] = useState(0);
  const [weight1at21, setWeight1at21] = useState(0);
  const [weight2at9, setWeight2at9] = useState(0);
  const [weight2at21, setWeight2at21] = useState(0);

  const currentYear = new Date().getFullYear();
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");
  const [selectedDate, setSelectedDate] = useState({
    year: { value: currentYear, label: currentYear },
    month: { value: currentMonth, label: currentMonth },
  });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const handleYearChange = (selectedOption) => {
    setSelectedDate((prevState) => ({
      ...prevState,
      year: selectedOption,
    }));
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedDate((prevState) => ({
      ...prevState,
      month: selectedOption,
    }));
  };

  const years = Array.from(
    { length: 11 },
    (_, i) => new Date().getFullYear() - 10 + i
  );

  const months = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );

  useEffect(() => {
    if (id === ":id") {
      setLoadingAndMsg({
        loading: false,
        msg: "Sorry! Device is not active or Invalid Input in Date/Time 😔",
      });
      setGraphLoadingAndMsg({
        graphLoading: false,
        msg: "Sorry! Device is not active or Invalid Input in Date/Time 😔",
      });
    } else {
      getTable1Data();
      getTable2Data();
      getTable3Data();
    }
  }, []);

  useEffect(() => {
    if (id == ":id") {
      setGraphLoadingAndMsg({
        graphLoading: false,
        msg: "Sorry! Device is not active or Invalid Input in Date/Time 😔",
      });
    } else {
      getGraph1Data();
    }
  }, [selectedDate]);

  async function getTable1Data() {
    const endTimestamp = Date.now(); // Current time
    const startTimestamp = endTimestamp - 24 * 60 * 60 * 1000;

    setLoadingAndMsg({
      loading: true,
      msg: null,
    });
    setGraphLoadingAndMsg({
      graphLoading: true,
      msg: null,
    });

    try {
      let temp = await getIotDataApi({
        device_id: id.split(":")[1].trim(),
        start: startTimestamp,
        end: endTimestamp,
      });

      temp = temp.data.responseDataa.Items;

      // Create a new array with modified objects (updatedTemp)
      const updatedTemp = temp.map((item) => {
        const date = moment(item?.timestamp).format("YYYY/MM/DD");
        const time = moment(item?.timestamp).format("HH:mm:ss");

        // Return a new object with updated properties
        return {
          ...item,
          payload: {
            ...item.payload,
            time: time,
            date: date,
          },
          timestamp: `${date} ${time} UTC`,
        };
      });

      // Sorting based on time using updatedTemp
      updatedTemp.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      // Set data and loading states
      setData(updatedTemp);
      setLoadingAndMsg({
        loading: false,
        msg: null,
      });
      setGraphLoadingAndMsg({
        graphLoading: false,
        msg: null,
      });

      // Updating the respective data
      updateTemperatureData(updatedTemp);
      updateWeightData(updatedTemp);
      updateHumidityData(updatedTemp);
    } catch (error) {
      setLoadingAndMsg({
        loading: false,
        msg: "An error occurred while fetching data. Please try again later.",
      });
      setGraphLoadingAndMsg({
        graphLoading: false,
        msg: "An error occurred while fetching data. Please try again later.",
      });
    }
  }

  async function getTable2Data() {
    setLoadingAndMsg({
      loading: true,
      msg: null,
    });
    setGraphLoadingAndMsg({
      graphLoading: true,
      msg: null,
    });

    try {
      const currentDate = new Date();
      currentDate.setHours(9, 0, 0, 0);
      const startTimestamp = currentDate.getTime();
      const endTimestamp = Date.now();

      // Fetch data from API
      let response = await getIotDataApi({
        device_id: id?.split(":")[1].trim(),
        start: startTimestamp,
        end: endTimestamp,
      });

      let data = response?.data?.responseDataa?.Items;

      // Check if data exists and map it to create new objects with the added property
      const updatedData = data?.map((item) => {
        const measurementTimestamp = new Date(item.timestamp).getTime();
        const differenceInMillis = measurementTimestamp - startTimestamp;
        const differenceInMinutes = Math.floor(
          differenceInMillis / (1000 * 60)
        );

        // Return a new object with all original properties and the added differenceInMinutes
        return {
          ...item,
          differenceInMinutes: differenceInMinutes, // add new property here
        };
      });

      // Sort the updatedData based on timestamp
      updatedData?.sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );

      // Use the sorted data
      if (updatedData && updatedData.length > 0) {
        setCurrentweight1(
          updatedData[updatedData.length - 1].payload.sensor_HX711_1
        );
        setCurrentweight2(
          updatedData[updatedData.length - 1].payload.sensor_HX711_2
        );
        setCurrWeight1at9(updatedData[0].payload.sensor_HX711_1);
        setCurrWeight2at9(updatedData[0].payload.sensor_HX711_2);
      }
    } catch (error) {
      console.error("Error fetching or processing data:", error);
    }
  }

  async function getTable3Data() {
    const currentTimestamp = Date.now();
    const yesterdayTimestamp = currentTimestamp - 24 * 60 * 60 * 1000;
    const endTimestamp = new Date(yesterdayTimestamp);
    endTimestamp.setHours(21, 0, 0, 0);
    const startTimestamp = new Date(yesterdayTimestamp);
    startTimestamp.setHours(9, 0, 0, 0);

    setLoadingAndMsg({
      loading: true,
      msg: null,
    });
    setGraphLoadingAndMsg({
      graphLoading: true,
      msg: null,
    });

    try {
      let temp = await getIotDataApi({
        device_id: id.split(":")[1].trim(),
        start: startTimestamp.getTime(),
        end: endTimestamp.getTime(),
      });

      temp = temp.data.responseDataa.Items;

      // Create a new array with modified objects (updatedTemp)
      const updatedTemp = temp.map((item) => {
        const date = moment(item?.timestamp).format("YYYY/MM/DD");
        const time = moment(item?.timestamp).format("HH:mm:ss");

        // Return a new object with updated properties
        return {
          ...item,
          payload: {
            ...item.payload,
            time: time,
            date: date,
          },
          timestamp: `${date} ${time} UTC`,
        };
      });

      // Sorting updatedTemp based on timestamp
      updatedTemp.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      // Setting the necessary weights using the sorted updatedTemp array
      setWeight1at9(updatedTemp[0].payload.sensor_HX711_1);
      setWeight1at21(
        updatedTemp[updatedTemp.length - 1].payload.sensor_HX711_1
      );
      setWeight2at9(updatedTemp[0].payload.sensor_HX711_2);
      setWeight2at21(
        updatedTemp[updatedTemp.length - 1].payload.sensor_HX711_2
      );
    } catch (error) {
      setLoadingAndMsg({
        loading: false,
        msg: "An error occurred while fetching data. Please try again later.",
      });
      setGraphLoadingAndMsg({
        graphLoading: false,
        msg: "An error occurred while fetching data. Please try again later.",
      });
    }
  }

  async function getGraph1Data() {
    if (selectedDate.year && selectedDate.month) {
      try {
        setGraphLoadingAndMsg({ graphLoading: true, msg: "Loading..." });

        const response = await getMonthlyDataApi({
          year: String(selectedDate.year.value),
          month: selectedDate.month.value,
          device_id: id.split(":")[1].trim(),
        });

        const { labels, weight1Diff, weight2Diff } = processData(
          response.data.body
        );

        setChartData({
          labels,
          datasets: [
            {
              label: "Weight 1 Difference",
              data: weight1Diff,
              fill: false,
              backgroundColor: "rgba(75, 192, 192, 0.6)", // Set color for all bars in this dataset
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
            {
              label: "Weight 2 Difference",
              data: weight2Diff,
              fill: false,
              backgroundColor: "rgba(20, 90, 192, 0.6)", // Set color for all bars in this dataset
              borderColor: "rgb(192, 75, 75)",
              tension: 0.1,
            },
          ],
        });

        setLoadingAndMsg({ loading: false, msg: null });
        setGraphLoadingAndMsg({ graphLoading: false, msg: null });
      } catch (error) {
        console.error("Error fetching monthly data:", error);
        setLoadingAndMsg({
          loading: false,
          msg: "Failed to fetch monthly data",
        });
        setGraphLoadingAndMsg({
          graphLoading: false,
          msg: "Failed to fetch monthly data",
        });
      }
    } else {
      setLoadingAndMsg({ loading: false, msg: null });
      setGraphLoadingAndMsg({ graphLoading: false, msg: null });
      setChartData({
        labels: [],
        datasets: [],
      });
    }
  }

  const processData = (data) => {
    const labels = [];
    const weight1Diff = [];
    const weight2Diff = [];

    data.forEach((item) => {
      const { date, morning, evening } = item;
      labels.push(date);

      const morningWeight1 = morning.payload?.sensor_HX711_1 || 0;
      const eveningWeight1 = evening.payload?.sensor_HX711_1 || 0;
      const weight1Difference = eveningWeight1 - morningWeight1;
      weight1Diff.push(weight1Difference);

      const morningWeight2 = morning.payload?.sensor_HX711_2 || 0;
      const eveningWeight2 = evening.payload?.sensor_HX711_2 || 0;
      const weight2Difference = eveningWeight2 - morningWeight2;
      weight2Diff.push(weight2Difference);
    });

    return { labels, weight1Diff, weight2Diff };
  };

  // Function to update temperature data after fetching
  function updateTemperatureData(data) {
    let temp1 = [];
    let temp2 = [];
    let temp3 = [];

    // Extract temperature data from fetched data
    for (let i = 0; i < data.length; i++) {
      const tempData = data[i]?.payload;

      if (tempData) {
        temp1.push(tempData.sensor_SHT_1[0]);
        temp2.push(tempData.sensor_SHT_2[0]);
        temp3.push(tempData.sensor_SHT_3[0]);
      }
    }

    // Update state with temperature data
    setTemp1Value(temp1);
    setTemp2Value(temp2);
    setTemp3Value(temp3);
  }

  function updateWeightData(data) {
    let arrWeight1 = [];
    let arrWeight2 = [];

    for (let i = 0; i < data.length; i++) {
      const tempData = data[i]?.payload;

      if (tempData) {
        arrWeight1.push(tempData.sensor_HX711_1);
        arrWeight2.push(tempData.sensor_HX711_2);
      }
    }
    setWeight1Value(arrWeight1);
    setWeight2Value(arrWeight2);
  }

  function updateHumidityData(data) {
    let arrayHumidity1 = [];
    let arrayHumidity2 = [];
    let arrayHumidity3 = [];

    for (let i = 0; i < data.length; i++) {
      const tempData = data[i]?.payload;

      if (tempData) {
        arrayHumidity1.push(
          tempData.sensor_DHT_1
            ? tempData.sensor_DHT_1[1]
            : tempData.sensor_SHT_1[1]
        );
        arrayHumidity2.push(
          tempData.sensor_DHT_2
            ? tempData.sensor_DHT_2[1]
            : tempData.sensor_SHT_2[1]
        );
        arrayHumidity3.push(
          tempData.sensor_DHT_3
            ? tempData.sensor_DHT_3[1]
            : tempData.sensor_SHT_3[1]
        );
      }
    }
    setHumidity1Value(arrayHumidity1);
    setHumidity2Value(arrayHumidity2);
    setHumidity3Value(arrayHumidity3);
  }

  function calculateAverage(arr) {
    if (arr.length === 0) return 0;
    const sum = arr.reduce((total, current) => total + current, 0);
    return Number.parseFloat(sum / arr.length).toFixed(2);
  }

  const { loading, msg } = loadingAndMsg;
  const { graphLoading, graphMsg } = graphLoadingAndMsg;

  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <h1>
              {id === ":id" ? (
                "Last Device You Viewed"
              ) : (
                <>
                  <strong style={{ color: "rgb(0, 214, 180)" }}>
                    {id.split(":")[0].trim()}:{" "}
                  </strong>{" "}
                  {id.split(":")[1].trim()}
                </>
              )}
            </h1>
          </Col>
          <Col xs="12">
            <h4>
              To view Graph visit <Link to={`/admin/graph/${id}`}>Graph</Link>
            </h4>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Daily Analytics</CardTitle>
                <p>
                  Daily information about the Temperature, Humidity (x3) and
                  Weights (x1 or x2) from previous day to current day.
                </p>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <>
                    {msg ? (
                      <Alert
                        style={{
                          textAlign: "center",
                          fontSize: "1.1rem",
                          margin: "auto",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: "2rem",
                        }}
                        color="danger"
                      >
                        {msg}
                      </Alert>
                    ) : (
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Unit</th>
                            <th>Maximum</th>
                            <th>Minimum</th>
                            <th className="text-center">Average</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Inside Beehive 1: Temperature</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...temp1Value).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...temp1Value).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(temp1Value)}
                            </td>
                          </tr>
                          <tr>
                            <td>Inside Beehive 1: Relative Humidity</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...humidity1Value).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...humidity1Value).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {" "}
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(humidity1Value)}
                            </td>
                          </tr>
                          <tr>
                            <td>Inside Beehive 2: Temperature</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...temp2Value).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...temp2Value).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(temp2Value)}
                            </td>
                          </tr>
                          <tr>
                            <td>Inside Beehive 2: Relative Humidity</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...humidity2Value).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...humidity2Value).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {" "}
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(humidity2Value)}
                            </td>
                          </tr>
                          <tr>
                            <td>Environment: Temperature</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...temp3Value).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...temp3Value).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(temp3Value)}
                            </td>
                          </tr>
                          <tr>
                            <td>Environment: Relative Humidity</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...humidity3Value).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...humidity3Value).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(humidity3Value)}
                            </td>
                          </tr>
                          <tr>
                            <td>Beehive 1: Weight</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...weight1Value).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...weight1Value).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(weight1Value)}
                            </td>
                          </tr>
                          <tr>
                            <td>Beehive 2: Weight</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.max(...weight2Value).toFixed(2)}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : Math.min(...weight2Value).toFixed(2)}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : calculateAverage(weight2Value)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle tag="h4">Weight Difference</CardTitle>
                <p>
                  weight difference of Weights (x1 or x2) from 9:00 to 21:00 of
                  previous day
                </p>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <>
                    {msg ? (
                      <Alert
                        style={{
                          textAlign: "center",
                          fontSize: "1.1rem",
                          margin: "auto",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: "2rem",
                        }}
                        color="danger"
                      >
                        {msg}
                      </Alert>
                    ) : (
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Unit</th>
                            <th>Weight at 9:00</th>
                            <th>Weight at 21:00</th>
                            <th>Differnce in weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beehive 1: Weight</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : weight1at9}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : weight1at21}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : weight1at21 - weight1at9}
                            </td>
                          </tr>
                          <tr>
                            <td>Beehive 2: Weight</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : weight2at9}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : weight2at21}
                            </td>
                            <td className="text-center">
                              {" "}
                              {loading && msg == null
                                ? "loading..."
                                : weight2at21 - weight2at9}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle tag="h4">Weight Difference</CardTitle>
                <p>
                  weight difference of Weights (x1 or x2) from current
                  measurement in weight to 09:00 of the same day
                </p>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <>
                    {msg ? (
                      <Alert
                        style={{
                          textAlign: "center",
                          fontSize: "1.1rem",
                          margin: "auto",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: "2rem",
                        }}
                        color="danger"
                      >
                        {msg}
                      </Alert>
                    ) : (
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Unit</th>
                            <th>Weight at 9:00</th>
                            <th>Weight at 21:00</th>
                            <th>Difference in weight</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Beehive 1: Weight</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : currWeight1at9}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : currentweight1}
                            </td>
                            <td className="text-center">
                              {loading && msg == null
                                ? "loading..."
                                : currentweight1 - currWeight1at9}
                            </td>
                          </tr>
                          <tr>
                            <td>Beehive 2: Weight</td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : currWeight2at9}
                            </td>
                            <td>
                              {loading && msg == null
                                ? "loading..."
                                : currentweight2}
                            </td>
                            <td className="text-center">
                              {" "}
                              {loading && msg == null
                                ? "loading..."
                                : currentweight2 - currWeight2at9}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Beehive 1 and Beehive 2 Weight Difference
                </CardTitle>
                <p>
                  Daily Weight difference of Beehive 1 Weight and Beehive 2
                  Weight from 09:00 to 21:00
                </p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="5">
                    <ReactSelect
                      value={selectedDate.year}
                      onChange={handleYearChange}
                      options={years.map((year) => ({
                        value: year,
                        label: year,
                      }))}
                      placeholder="Select Year"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          borderRadius: "0.25rem",
                          border: "1px solid #ced4da",
                          fontSize: "1rem",
                          lineHeight: 1.5,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#495057",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "0.25rem",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "transparent",
                          color: state.isSelected ? "#fff" : "#495057",
                          ":hover": {
                            backgroundColor: "#f8f9fa",
                            color: "#212529",
                          },
                        }),
                      }}
                    />
                  </Col>
                  <Col sm="5">
                    <ReactSelect
                      value={selectedDate.month}
                      onChange={handleMonthChange}
                      options={months.map((month) => ({
                        value: month,
                        label: month,
                      }))}
                      placeholder="Select Month"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          borderRadius: "0.25rem",
                          border: "1px solid #ced4da",
                          fontSize: "1rem",
                          lineHeight: 1.5,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#495057",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "0.25rem",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "transparent",
                          color: state.isSelected ? "#fff" : "#495057",
                          ":hover": {
                            backgroundColor: "#f8f9fa",
                            color: "#212529",
                          },
                        }),
                      }}
                    />
                  </Col>
                </Row>
                {graphLoading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <Line
                    data={chartData}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                      },
                    }}
                  />
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Beehive 1 and Beehive 2 Weight Difference
                </CardTitle>
                <p>
                  Daily Weight difference of Beehive 1 Weight and Beehive 2
                  Weight from 09:00 to 21:00
                </p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="5">
                    <ReactSelect
                      value={selectedDate.year}
                      onChange={handleYearChange}
                      options={years.map((year) => ({
                        value: year,
                        label: year,
                      }))}
                      placeholder="Select Year"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          borderRadius: "0.25rem",
                          border: "1px solid #ced4da",
                          fontSize: "1rem",
                          lineHeight: 1.5,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#495057",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "0.25rem",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "transparent",
                          color: state.isSelected ? "#fff" : "#495057",
                          ":hover": {
                            backgroundColor: "#f8f9fa",
                            color: "#212529",
                          },
                        }),
                      }}
                    />
                  </Col>
                  <Col sm="5">
                    <ReactSelect
                      value={selectedDate.month}
                      onChange={handleMonthChange}
                      options={months.map((month) => ({
                        value: month,
                        label: month,
                      }))}
                      placeholder="Select Month"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          borderRadius: "0.25rem",
                          border: "1px solid #ced4da",
                          fontSize: "1rem",
                          lineHeight: 1.5,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#495057",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "0.25rem",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#007bff"
                            : "transparent",
                          color: state.isSelected ? "#fff" : "#495057",
                          ":hover": {
                            backgroundColor: "#f8f9fa",
                            color: "#212529",
                          },
                        }),
                      }}
                    />
                  </Col>
                </Row>
                {graphLoading ? (
                  <div className="text-center">
                    <Spinner
                      className="justify-content-center"
                      animation="grow"
                      style={{
                        height: "7rem",
                        width: "7rem",
                        marginTop: "3.5rem",
                        color: "#263B90",
                      }}
                    />
                    <h6 style={{ padding: "1rem" }}>Loading...</h6>
                  </div>
                ) : (
                  <Bar
                    data={chartData}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                        },
                      },
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
