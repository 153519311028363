import { configureStore } from "@reduxjs/toolkit";
import { sasloWebApi, deviceSlice } from "state/slices/webSlices";

export const store = configureStore({
  reducer: {
    [sasloWebApi.reducerPath]: sasloWebApi.reducer,
    [deviceSlice.reducerPath]: deviceSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sasloWebApi.middleware),
});
