import React, { useContext, useState } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom"; // Updated imports
import { UserContext } from "../context/context";
import Spinner from "react-bootstrap/Spinner";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { useLoginApiMutation } from "state/slices/webSlices"; // Use for Api calling (Rtk-query)

function Login() {
  //hooks
  const navigate = useNavigate(); // Updated from useHistory to useNavigate
  const { addUser, user } = useContext(UserContext);
  const [loadingAndMsg, setLoadingAndMsg] = useState({
    loading: false,
    error: null,
  });

  const [loginApi] = useLoginApiMutation(); // for api call

  //Form Validation and login API call
  const validate = (e) => {
    e.preventDefault();

    //Getting all inputs fields
    let email = e.target.loginEmail.value;
    let password = e.target.loginPassword.value;
    let errDiv = document.getElementById("errDiv");
    let errMsg = [];
    let regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // Validating input fields
    if (email === "" || !regex.test(email)) {
      errMsg.push("Please enter the valid Email address");
    }

    if (password === "") {
      errMsg.push("Please enter the Password");
    }

    // If there is an error in user input, display those errors
    if (errMsg.length > 0) {
      errDiv.style.display = "block";
      errDiv.style.color = "red";
      errDiv.style.border = "1px solid red";
      errDiv.style.borderRadius = "10px";
      errDiv.style.marginBottom = "1rem";
      errDiv.style.padding = "0.5rem";

      let msgs = "";

      for (let i = 0; i < errMsg.length; i++) {
        msgs += errMsg[i] + "<br/>";
      }

      errDiv.innerHTML = msgs;
      window.scrollTo(0, 0);
    } else {
      // if no input error -> send login request to Login API
      setLoadingAndMsg({
        loading: true,
        error: null,
      });
      errDiv.style.display = "none";
      const data = {
        username: email,
        password: password,
      };

      // Sending request to Login API
      loginApi(data)
        .then((res) => {
          // If there is error e.g. password incorrect or Email not verified, display that error
          if (res.data.statusCode === 400) {
            // setting style and message of errDiv

            setLoadingAndMsg({
              loading: false,
              error: res.data.body,
            });

            // In case of email not verified error, message was not proper. So, setting message manually

            if (res.data.body.code === "UserNotConfirmedException") {
              setLoadingAndMsg({
                loading: false,
                error:
                  "Email is not verified! Please verify your email then LogIn",
              });
            }
          } else {
            // if no error in login -> add user to global state and redirect to dashboard
            addUser({
              token: res.data.responseDataa.accessToken.jwtToken,
              role: res.data.responseDataa.idToken.payload["custom:role"],
              email: res.data.responseDataa.idToken.payload["email"],
            });
            if (res.data.responseDataa.idToken.payload["custom:role"] === "1") {
              navigate("/admin"); // Updated to use navigate
            } else {
              navigate("/user"); // Updated to use navigate
            }
          }
        })
        .catch((err) =>
          setLoadingAndMsg({
            loading: false,
            error: "Error Occurred",
          })
        );
      window.scrollTo(0, 0);
    }
  };

  const { loading, error } = loadingAndMsg;

  if (!user.token) {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="10">
              {loading ? (
                <div className="text-center" style={{ height: "70vh" }}>
                  <Spinner
                    className="justify-content-center"
                    animation="grow"
                    style={{
                      height: "10rem",
                      width: "10rem",
                      marginTop: "13rem",
                      color: "#263B90",
                    }}
                  />
                  <h6 style={{ padding: "1rem" }}>Loading...</h6>
                </div>
              ) : (
                <Card style={{ borderRadius: "1rem" }}>
                  <CardHeader>
                    <h3 className="">Login</h3>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={(e) => validate(e)}>
                      <Row>
                        <Col className="pl-md-1" md="4">
                          <FormGroup>
                            <div
                              name="errDiv"
                              id="errDiv"
                              style={{ display: "none" }}
                            ></div>
                          </FormGroup>
                        </Col>
                      </Row>

                      {error ? (
                        <Row>
                          <Col className="pl-md-1" md="4">
                            <FormGroup>
                              <div
                                name="errDiv"
                                id="errDiv"
                                style={{
                                  display: "block",
                                  fontSize: "1rem",
                                  fontWeight: "500",
                                  border: "1px solid red",
                                  borderRadius: "10px",
                                  padding: "0.5rem",
                                  color: "red",
                                }}
                              >
                                {error}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) : (
                        <div></div>
                      )}

                      <Row>
                        <Col className="pl-md-1" md="4">
                          <FormGroup>
                            <label htmlFor="exampleInputEmail1">
                              Email address
                            </label>
                            <Input
                              placeholder="Enter Email"
                              type="text"
                              name="loginEmail"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-md-1" md="4">
                          <FormGroup>
                            <label htmlFor="password">Password</label>
                            <Input
                              type="password"
                              placeholder="Enter Password"
                              name="loginPassword"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-md-1" md="4">
                          <FormGroup>
                            <Button
                              id="loginBtn"
                              className="btn-fill"
                              color="primary"
                              type="submit"
                            >
                              Login
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="pl-md-1" md="4">
                          <FormGroup>
                            <Link to="/forgetPassword">
                              <Button
                                className="btn-fill"
                                style={{ width: "100%" }}
                              >
                                Forget Password
                              </Button>
                            </Link>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  } else {
    if (user.role === "1") {
      return <Navigate to="/admin" />; // Updated Redirect
    } else {
      return <Navigate to="/user" />; // Updated Redirect
    }
  }
}

export default Login;
