import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { Line } from "react-chartjs-2";
import Spinner from "react-bootstrap/Spinner";

// This is Graph Component (All Graphs are ploted from this component)
function GraphComponent({ graphName, loading, data, options, msg }) {
  return (
    <Row>
      <Col xs="12">
        <Card className="card-chart">
          {/* Upper heading of graph */}
          <CardHeader>
            <Row>
              <Col className="text-left" sm="12">
                <CardTitle tag="h2" style={{ fontWeight: "500" }}>
                  {graphName}
                </CardTitle>
              </Col>
            </Row>
          </CardHeader>
          {/* Main graph Body */}
          <CardBody>
            <div className="chart-area" style={{ height: "50vh" }}>
              {loading ? (
                <div className="text-center">
                  <Spinner
                    className="justify-content-center"
                    animation="grow"
                    style={{
                      height: "7rem",
                      width: "7rem",
                      marginTop: "3.5rem",
                      color: "#00D6B4",
                    }}
                  />
                  <h6 style={{ padding: "1rem" }}>Loading...</h6>
                </div>
              ) : (
                <>
                  {msg ? (
                    <Alert
                      style={{
                        textAlign: "center",
                        fontSize: "1.1rem",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "2rem",
                      }}
                      color="danger"
                    >
                      {msg}
                    </Alert>
                  ) : (
                    <Line data={data} options={options} />
                  )}
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default GraphComponent;
