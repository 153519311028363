export const SENSORS = [
  "SHT_1",
  "SHT_2",
  "SHT_3",
  "NTC_1",
  "NTC_2",
  "NTC_3",
  "HX711_1",
  "HX711_2",
  "DHT_1",
  "DHT_2",
  "DS18B20_1",
  "DS18B20_2",
  "HX711",
];
